<template>
  <div
    class="modal fade"
    id="previewModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="expandedTaskModalTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-light">
          <h5 class="modal-title">Preview</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal" data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body bg-light" v-if="template">
          <div class="row">
            <div class="col" style="line-height: 1.25 !important">
              <div class="card shadow-sm">
                <div class="card-body">
                  <table
                    class="wrapper"
                    width="100%"
                    cellpadding="0"
                    cellspacing="0"
                    role="presentation"
                    style="
                      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',
                        Roboto, Helvetica, Arial, sans-serif,
                        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
                      box-sizing: border-box;
                      background-color: #fff;
                      margin: 0;
                      padding: 0;
                      width: 100%;
                      -premailer-cellpadding: 0;
                      -premailer-cellspacing: 0;
                      -premailer-width: 100%;
                    "
                  >
                    <tbody>
                      <tr>
                        <td
                          align="center"
                          style="
                            font-family: -apple-system, BlinkMacSystemFont,
                              'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
                              'Apple Color Emoji', 'Segoe UI Emoji',
                              'Segoe UI Symbol';
                            box-sizing: border-box;
                          "
                        >
                          <table
                            class="content"
                            width="100%"
                            cellpadding="0"
                            cellspacing="0"
                            role="presentation"
                            style="
                              font-family: -apple-system, BlinkMacSystemFont,
                                'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
                                'Apple Color Emoji', 'Segoe UI Emoji',
                                'Segoe UI Symbol';
                              box-sizing: border-box;
                              margin: 0;
                              padding: 0;
                              width: 100%;
                              -premailer-cellpadding: 0;
                              -premailer-cellspacing: 0;
                              -premailer-width: 100%;
                            "
                          >
                            <tbody>
                              <tr>
                                <td
                                  class="header"
                                  style="
                                    font-family: -apple-system,
                                      BlinkMacSystemFont, 'Segoe UI', Roboto,
                                      Helvetica, Arial, sans-serif,
                                      'Apple Color Emoji', 'Segoe UI Emoji',
                                      'Segoe UI Symbol';
                                    box-sizing: border-box;
                                    padding: 25px 0;
                                    text-align: center;
                                  "
                                >
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://app.dh"
                                    style="
                                      font-family: -apple-system,
                                        BlinkMacSystemFont, 'Segoe UI', Roboto,
                                        Helvetica, Arial, sans-serif,
                                        'Apple Color Emoji', 'Segoe UI Emoji',
                                        'Segoe UI Symbol';
                                      box-sizing: border-box;
                                      color: #bbbfc3;
                                      font-size: 19px;
                                      font-weight: bold;
                                      text-decoration: none;
                                      text-shadow: 0 1px 0 white;
                                    "
                                  >
                                    <img
                                      :src="user.clinic_logo"
                                      alt="Swandoola"
                                      width="125"
                                      style="
                                        font-family: -apple-system,
                                          BlinkMacSystemFont, 'Segoe UI', Roboto,
                                          Helvetica, Arial, sans-serif,
                                          'Apple Color Emoji', 'Segoe UI Emoji',
                                          'Segoe UI Symbol';
                                        box-sizing: border-box;
                                        max-width: 100%;
                                        border: none;
                                      "
                                    />
                                  </a>
                                </td>
                              </tr>
                              <!-- Email Body -->
                              <tr>
                                <td
                                  class="body"
                                  width="100%"
                                  cellpadding="0"
                                  cellspacing="0"
                                  style="
                                    font-family: -apple-system,
                                      BlinkMacSystemFont, 'Segoe UI', Roboto,
                                      Helvetica, Arial, sans-serif,
                                      'Apple Color Emoji', 'Segoe UI Emoji',
                                      'Segoe UI Symbol';
                                    box-sizing: border-box;
                                    background-color: #ffffff;
                                    border-bottom: 1px solid #edeff2;
                                    border-top: 1px solid #edeff2;
                                    margin: 0;
                                    padding: 0;
                                    width: 100%;
                                    -premailer-cellpadding: 0;
                                    -premailer-cellspacing: 0;
                                    -premailer-width: 100%;
                                  "
                                >
                                  <table
                                    class="inner-body"
                                    align="center"
                                    width="570"
                                    cellpadding="0"
                                    cellspacing="0"
                                    role="presentation"
                                    style="
                                      font-family: -apple-system,
                                        BlinkMacSystemFont, 'Segoe UI', Roboto,
                                        Helvetica, Arial, sans-serif,
                                        'Apple Color Emoji', 'Segoe UI Emoji',
                                        'Segoe UI Symbol';
                                      box-sizing: border-box;
                                      background-color: #ffffff;
                                      margin: 0 auto;
                                      padding: 0;
                                      width: 570px;
                                      -premailer-cellpadding: 0;
                                      -premailer-cellspacing: 0;
                                      -premailer-width: 570px;
                                    "
                                  >
                                    <!-- Body content -->
                                    <tbody>
                                      <tr>
                                        <td
                                          class="content-cell"
                                          style="
                                            font-family: -apple-system,
                                              BlinkMacSystemFont, 'Segoe UI',
                                              Roboto, Helvetica, Arial,
                                              sans-serif, 'Apple Color Emoji',
                                              'Segoe UI Emoji',
                                              'Segoe UI Symbol';
                                            box-sizing: border-box;
                                            padding: 35px;
                                          "
                                        >
                                          <h1
                                            style="
                                              font-family: -apple-system,
                                                BlinkMacSystemFont, 'Segoe UI',
                                                Roboto, Helvetica, Arial,
                                                sans-serif, 'Apple Color Emoji',
                                                'Segoe UI Emoji',
                                                'Segoe UI Symbol';
                                              box-sizing: border-box;
                                              color: #3d4852;
                                              font-size: 19px;
                                              font-weight: bold;
                                              margin-top: 0;
                                              text-align: left;
                                            "
                                          >
                                            {{ template.email_subject }}
                                          </h1>
                                          <div
                                            v-html="previewBodyWithShortcodes"
                                          ></div>
                                          <table
                                            class="action"
                                            align="center"
                                            width="100%"
                                            cellpadding="0"
                                            cellspacing="0"
                                            role="presentation"
                                            style="
                                              font-family: -apple-system,
                                                BlinkMacSystemFont, 'Segoe UI',
                                                Roboto, Helvetica, Arial,
                                                sans-serif, 'Apple Color Emoji',
                                                'Segoe UI Emoji',
                                                'Segoe UI Symbol';
                                              box-sizing: border-box;
                                              margin: 30px auto;
                                              padding: 0;
                                              text-align: center;
                                              width: 100%;
                                              -premailer-cellpadding: 0;
                                              -premailer-cellspacing: 0;
                                              -premailer-width: 100%;
                                            "
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  align="center"
                                                  style="
                                                    font-family: -apple-system,
                                                      BlinkMacSystemFont,
                                                      'Segoe UI', Roboto,
                                                      Helvetica, Arial,
                                                      sans-serif,
                                                      'Apple Color Emoji',
                                                      'Segoe UI Emoji',
                                                      'Segoe UI Symbol';
                                                    box-sizing: border-box;
                                                  "
                                                >
                                                  <table
                                                    width="100%"
                                                    border="0"
                                                    cellpadding="0"
                                                    cellspacing="0"
                                                    role="presentation"
                                                    style="
                                                      font-family: -apple-system,
                                                        BlinkMacSystemFont,
                                                        'Segoe UI', Roboto,
                                                        Helvetica, Arial,
                                                        sans-serif,
                                                        'Apple Color Emoji',
                                                        'Segoe UI Emoji',
                                                        'Segoe UI Symbol';
                                                      box-sizing: border-box;
                                                    "
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td
                                                          align="center"
                                                          style="
                                                            font-family: -apple-system,
                                                              BlinkMacSystemFont,
                                                              'Segoe UI', Roboto,
                                                              Helvetica, Arial,
                                                              sans-serif,
                                                              'Apple Color Emoji',
                                                              'Segoe UI Emoji',
                                                              'Segoe UI Symbol';
                                                            box-sizing: border-box;
                                                          "
                                                        >
                                                          <table
                                                            border="0"
                                                            cellpadding="0"
                                                            cellspacing="0"
                                                            role="presentation"
                                                            style="
                                                              font-family: -apple-system,
                                                                BlinkMacSystemFont,
                                                                'Segoe UI',
                                                                Roboto,
                                                                Helvetica, Arial,
                                                                sans-serif,
                                                                'Apple Color Emoji',
                                                                'Segoe UI Emoji',
                                                                'Segoe UI Symbol';
                                                              box-sizing: border-box;
                                                            "
                                                          >
                                                            <tbody>
                                                              <tr>
                                                                <td
                                                                  style="
                                                                    font-family: -apple-system,
                                                                      BlinkMacSystemFont,
                                                                      'Segoe UI',
                                                                      Roboto,
                                                                      Helvetica,
                                                                      Arial,
                                                                      sans-serif,
                                                                      'Apple Color Emoji',
                                                                      'Segoe UI Emoji',
                                                                      'Segoe UI Symbol';
                                                                    box-sizing: border-box;
                                                                  "
                                                                >
                                                                  <a
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    href="https://clients.swandoola.com/login"
                                                                    class="
                                                                      button
                                                                      button-primary
                                                                    "
                                                                    style="
                                                                      font-family: -apple-system,
                                                                        BlinkMacSystemFont,
                                                                        'Segoe UI',
                                                                        Roboto,
                                                                        Helvetica,
                                                                        Arial,
                                                                        sans-serif,
                                                                        'Apple Color Emoji',
                                                                        'Segoe UI Emoji',
                                                                        'Segoe UI Symbol';
                                                                      box-sizing: border-box;
                                                                      border-radius: 3px;
                                                                      box-shadow: 0
                                                                        2px 3px
                                                                        rgba(
                                                                          0,
                                                                          0,
                                                                          0,
                                                                          0.16
                                                                        );
                                                                      color: #fff;
                                                                      display: inline-block;
                                                                      text-decoration: none;
                                                                      -webkit-text-size-adjust: none;
                                                                      background-color: #03006b;
                                                                      border-top: 10px
                                                                        solid
                                                                        #03006b;
                                                                      border-right: 18px
                                                                        solid
                                                                        #03006b;
                                                                      border-bottom: 10px
                                                                        solid
                                                                        #03006b;
                                                                      border-left: 18px
                                                                        solid
                                                                        #03006b;
                                                                    "
                                                                    >Client
                                                                    Portal</a
                                                                  >
                                                                </td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <p
                                            style="
                                              font-family: -apple-system,
                                                BlinkMacSystemFont, 'Segoe UI',
                                                Roboto, Helvetica, Arial,
                                                sans-serif, 'Apple Color Emoji',
                                                'Segoe UI Emoji',
                                                'Segoe UI Symbol';
                                              box-sizing: border-box;
                                              color: #3d4852;
                                              font-size: 16px;
                                              line-height: 1.5em;
                                              margin-top: 0;
                                              text-align: left;
                                            "
                                          >
                                            <i
                                              style="
                                                font-family: -apple-system,
                                                  BlinkMacSystemFont, 'Segoe UI',
                                                  Roboto, Helvetica, Arial,
                                                  sans-serif,
                                                  'Apple Color Emoji',
                                                  'Segoe UI Emoji',
                                                  'Segoe UI Symbol';
                                                box-sizing: border-box;
                                              "
                                              >This is an automated email,
                                              please do not reply.</i
                                            >
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style="
                                    font-family: -apple-system,
                                      BlinkMacSystemFont, 'Segoe UI', Roboto,
                                      Helvetica, Arial, sans-serif,
                                      'Apple Color Emoji', 'Segoe UI Emoji',
                                      'Segoe UI Symbol';
                                    box-sizing: border-box;
                                  "
                                >
                                  <table
                                    class="footer"
                                    align="center"
                                    width="570"
                                    cellpadding="0"
                                    cellspacing="0"
                                    role="presentation"
                                    style="
                                      font-family: -apple-system,
                                        BlinkMacSystemFont, 'Segoe UI', Roboto,
                                        Helvetica, Arial, sans-serif,
                                        'Apple Color Emoji', 'Segoe UI Emoji',
                                        'Segoe UI Symbol';
                                      box-sizing: border-box;
                                      margin: 0 auto;
                                      padding: 0;
                                      text-align: center;
                                      width: 570px;
                                      -premailer-cellpadding: 0;
                                      -premailer-cellspacing: 0;
                                      -premailer-width: 570px;
                                    "
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          class="content-cell"
                                          align="center"
                                          style="
                                            font-family: -apple-system,
                                              BlinkMacSystemFont, 'Segoe UI',
                                              Roboto, Helvetica, Arial,
                                              sans-serif, 'Apple Color Emoji',
                                              'Segoe UI Emoji',
                                              'Segoe UI Symbol';
                                            box-sizing: border-box;
                                            padding: 35px;
                                          "
                                        >
                                          <p
                                            style="
                                              font-family: -apple-system,
                                                BlinkMacSystemFont, 'Segoe UI',
                                                Roboto, Helvetica, Arial,
                                                sans-serif, 'Apple Color Emoji',
                                                'Segoe UI Emoji',
                                                'Segoe UI Symbol';
                                              box-sizing: border-box;
                                              line-height: 1.5em;
                                              margin-top: 0;
                                              color: #aeaeae;
                                              font-size: 12px;
                                              text-align: center;
                                            "
                                          >
                                            © 2020 Swandoola App. All rights
                                            reserved.
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer bg-light">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal" data-dismiss="modal"
            aria-label="Close"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["user", "template"],
  data() {
    return {};
  },
  methods: {
    showPreviewModal(t) {
      this.template = t;
      $("#previewModal").modal("show");
    },
  },
  computed: {
    previewBodyWithShortcodes() {
      if (this.template.email_body) {
        let str = this.template.email_body.replace("[my-name]", this.user.name);
        str = str.replace("[clinic-name]", this.user.clinic.name);
        str = str.replace("[client-name]", "Jane Doe");
        str = str.replace("[clinic-email]", this.user.clinic.email_address);
        str = str.replace("[client-first-name]", "Jane");
        str = str.replace("[event-start]", "July 18th @ 14:30pm");
        str = str.replace("[order-id]", "#1234");
        str = str.replace("[order-items]", "<b>Preview Unavailable</b>");
        str = str.replace("[order-title]", "An example order title");
        str = str.replace(
          "[order-description]",
          "An example order description"
        );
        str = str.replace("[order-total]", "£199.99");
        str = str.replace(
          "[order-shipping-address]",
          "<b>Preview Unavailable</b>"
        );
        str = str.replace("[file-name]", "<b>Terms & Agreements.pdf</b>");
        return str;
      }
      return "";
    },
  },
  components: {},
};
</script>