<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3 spark-settings-tabs">
        <marketing-nav></marketing-nav>
      </div>

      <!-- Tab cards -->
      <div class="col-md-9 ps-0">
        <busy :visible="loading" />
        <div v-if="emailSettings">
          <div class="row mb-3" id="client">
            <div class="col">
              <div class="card border-0 shadow-sm">
                <div class="card-header">Automated Client Emails</div>
                <div class="card-body pt-0">
                  <div class="row mb-3">
                    <div class="col">
                      <p class="alert alert-primary mb-3">
                        Configure which automated system emails should be sent
                        to your clients by default. These are default settings
                        which you can override each time you perform a
                        relevant action.
                      </p>
                      <div class="custom-control custom-switch ms-2">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="emailSync"
                          v-model="emailSettings.enable_client_system_emails"
                          @change="updateEmailSettings"
                        />
                        <label class="custom-control-label" for="emailSync"
                          >Enable Automated Client Email Notifications</label
                        >
                      </div>
                    </div>
                  </div>

                  <div v-if="emailSettings.enable_client_system_emails">
                    <table class="table table-striped shadow-sm mx-auto">
                      <tbody>
                        <tr class="border-top: 0px">
                          <td style="border-top: 0px">
                            <div class="custom-control custom-switch pt-1">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="appointments"
                                v-model="
                                  emailSettings.enable_appointment_emails
                                "
                                @change="updateEmailSettings"
                              />
                              <label
                                class="custom-control-label"
                                for="appointments"
                              >
                                Appointment Notifications
                              </label>
                            </div>
                          </td>
                          <td class="text-end" style="border-top: 0px">
                            <div
                              v-if="emailSettings.enable_appointment_emails"
                              class="d-flex"
                            >
                              <select
                                class="form-control form-control-sm d-inline flex-fill me-2"
                                v-model="
                                  emailSettings.appointment_notification_email_template_id
                                "
                                @change="updateEmailSettings"
                                required
                              >
                                <option :value="null" selected disabled>
                                  Select an Email Template (Type: Appointment)
                                </option>
                                <option
                                  v-for="template in emailTemplates.filter(
                                    function (template) {
                                      return template.type === 'appointment';
                                    }
                                  )"
                                  :key="template.id"
                                  :value="template.id"
                                >
                                  {{ template.title }}
                                </option>
                              </select>
                              <button
                                class="btn btn-xs rounded btn-outline-primary d-inline"
                                @click="
                                  showPreviewModal(
                                    emailSettings.appointment_notification_email_template_id
                                  )
                                "
                              >
                                <i class="far fa-eye"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr class="border-top: 0px">
                          <td style="border-top: 0px">
                            <div class="custom-control custom-switch pt-1">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="appointments-cancel"
                                v-model="
                                  emailSettings.enable_appointment_cancel_emails
                                "
                                @change="updateEmailSettings"
                              />
                              <label
                                class="custom-control-label"
                                for="appointments-cancel"
                              >
                                Appointment Cancellation Notifications
                              </label>
                            </div>
                          </td>
                          <td class="text-end" style="border-top: 0px">
                            <div
                              v-if="
                                emailSettings.enable_appointment_cancel_emails
                              "
                              class="d-flex"
                            >
                              <select
                                class="form-control form-control-sm d-inline flex-fill me-2"
                                v-model="
                                  emailSettings.appointment_cancel_notification_email_template_id
                                "
                                @change="updateEmailSettings"
                                required
                              >
                                <option :value="null" selected disabled>
                                  Select an Email Template (Type: Appointment)
                                </option>
                                <option
                                  v-for="template in emailTemplates.filter(
                                    function (template) {
                                      return template.type === 'appointment';
                                    }
                                  )"
                                  :key="template.id"
                                  :value="template.id"
                                >
                                  {{ template.title }}
                                </option>
                              </select>
                              <button
                                class="btn btn-xs rounded btn-outline-primary d-inline"
                                @click="
                                  showPreviewModal(
                                    emailSettings.appointment_cancel_notification_email_template_id
                                  )
                                "
                              >
                                <i class="far fa-eye"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr class="border-top: 0px">
                          <td style="border-top: 0px">
                            <div class="custom-control custom-switch pt-1">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="appointments-payment"
                                v-model="
                                  emailSettings.enable_appointment_payment_emails
                                "
                                @change="updateEmailSettings"
                              />
                              <label
                                class="custom-control-label"
                                for="appointments-payment"
                              >
                                Appointment Payment Notifications
                              </label>
                            </div>
                          </td>
                          <td class="text-end" style="border-top: 0px">
                            <div
                              v-if="
                                emailSettings.enable_appointment_payment_emails
                              "
                              class="d-flex"
                            >
                              <select
                                class="form-control form-control-sm d-inline flex-fill me-2"
                                v-model="
                                  emailSettings.appointment_payment_notification_email_template_id
                                "
                                @change="updateEmailSettings"
                                required
                              >
                                <option :value="null" selected disabled>
                                  Select an Email Template (Type: Appointment)
                                </option>
                                <option
                                  v-for="template in emailTemplates.filter(
                                    function (template) {
                                      return template.type === 'appointment';
                                    }
                                  )"
                                  :key="template.id"
                                  :value="template.id"
                                >
                                  {{ template.title }}
                                </option>
                              </select>
                              <button
                                class="btn btn-xs rounded btn-outline-primary d-inline"
                                @click="
                                  showPreviewModal(
                                    emailSettings.appointment_payment_notification_email_template_id
                                  )
                                "
                              >
                                <i class="far fa-eye"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="custom-control custom-switch mb-1">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="files"
                                v-model="emailSettings.enable_files_emails"
                                @change="updateEmailSettings"
                              />
                              <label class="custom-control-label" for="files">
                                File Share Notifications
                              </label>
                            </div>
                          </td>
                          <td>
                            <div
                              v-if="emailSettings.enable_files_emails"
                              class="d-flex"
                            >
                              <select
                                class="form-control form-control-sm d-inline flex-fill me-2"
                                v-model="
                                  emailSettings.files_email_template_id
                                "
                                @change="updateEmailSettings"
                                required
                              >
                                <option :value="null" selected disabled>
                                  Select an Email Template (Type: File)
                                </option>
                                <option
                                  v-for="template in emailTemplates.filter(
                                    function (template) {
                                      return template.type === 'file';
                                    }
                                  )"
                                  :key="template.id"
                                  :value="template.id"
                                >
                                  {{ template.title }}
                                </option>
                              </select>
                              <button
                                class="btn btn-xs rounded btn-outline-primary d-inline"
                                @click="
                                  showPreviewModal(
                                    emailSettings.files_email_template_id
                                  )
                                "
                              >
                                <i class="far fa-eye"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="custom-control custom-switch mb-1">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="orders"
                                v-model="emailSettings.enable_order_emails"
                                @change="updateEmailSettings"
                              />
                              <label
                                class="custom-control-label"
                                for="orders"
                              >
                                Order Notifications
                              </label>
                            </div>
                          </td>
                          <td>
                            <div
                              v-if="emailSettings.enable_order_emails"
                              class="d-flex"
                            >
                              <select
                                class="form-control form-control-sm d-inline flex-fill me-2"
                                v-model="
                                  emailSettings.order_notification_email_template_id
                                "
                                @change="updateEmailSettings"
                                required
                              >
                                <option :value="null" selected disabled>
                                  Select an Email Template (Type: Order)
                                </option>
                                <option
                                  v-for="template in emailTemplates.filter(
                                    function (template) {
                                      return template.type === 'order';
                                    }
                                  )"
                                  :key="template.id"
                                  :value="template.id"
                                >
                                  {{ template.title }}
                                </option>
                              </select>
                              <button
                                class="btn btn-xs rounded btn-outline-primary d-inline"
                                @click="
                                  showPreviewModal(
                                    emailSettings.order_notification_email_template_id
                                  )
                                "
                              >
                                <i class="far fa-eye"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="custom-control custom-switch mb-1">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="resources"
                                v-model="
                                  emailSettings.enable_resources_emails
                                "
                                @change="updateEmailSettings"
                              />
                              <label
                                class="custom-control-label"
                                for="resources"
                              >
                                Resource Share Notifications
                              </label>
                            </div>
                          </td>
                          <td>
                            <div
                              v-if="emailSettings.enable_resources_emails"
                              class="d-flex"
                            >
                              <select
                                class="form-control form-control-sm d-inline flex-fill me-2"
                                v-model="
                                  emailSettings.resources_email_template_id
                                "
                                @change="updateEmailSettings"
                                required
                              >
                                <option :value="null" selected disabled>
                                  Select an Email Template (Type: File)
                                </option>
                                <option
                                  v-for="template in emailTemplates.filter(
                                    function (template) {
                                      return template.type === 'file';
                                    }
                                  )"
                                  :key="template.id"
                                  :value="template.id"
                                >
                                  {{ template.title }}
                                </option>
                              </select>
                              <button
                                class="btn btn-xs rounded btn-outline-primary d-inline"
                                @click="
                                  showPreviewModal(
                                    emailSettings.resources_email_template_id
                                  )
                                "
                              >
                                <i class="far fa-eye"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="custom-control custom-switch">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="welcomeEmails"
                                v-model="emailSettings.enable_welcome_emails"
                                @change="updateEmailSettings"
                              />
                              <label
                                class="custom-control-label"
                                for="welcomeEmails"
                                >Enable Automated Client Welcome Emails</label
                              >
                            </div>
                          </td>
                          <td class="text-end">
                            <div
                              v-if="emailSettings.enable_welcome_emails"
                              class="d-flex"
                            >
                              <select
                                class="form-control form-control-sm flex-fill me-2 d-inline"
                                v-model="
                                  emailSettings.welcome_email_template_id
                                "
                                @change="updateEmailSettings"
                                required
                              >
                                <option :value="null" selected disabled>
                                  Select an Email Template (Type: Client)
                                </option>
                                <option
                                  v-for="template in emailTemplates.filter(
                                    function (template) {
                                      return template.type === 'client';
                                    }
                                  )"
                                  :key="template.id"
                                  :value="template.id"
                                >
                                  {{ template.title }}
                                </option>
                              </select>
                              <button
                                class="btn btn-xs rounded btn-outline-primary d-inline flex-1"
                                @click="
                                  showPreviewModal(
                                    emailSettings.welcome_email_template_id
                                  )
                                "
                              >
                                <i class="far fa-eye"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div v-if="user.id === user.clinic.owner_id">
                      <label>
                        Automated Email No-Reply Message
                        <i class="far fa-info-circle text-primary ms-1"
                           v-tooltip:bottom="'This message will be appended to all automated system emails which do not support replies.'"
                        ></i>
                      </label>
                      <textarea class="form-control"
                                rows="3"
                                v-model="emailSettings.noreply_message"/>
                    </div>
                  </div>
                </div>
              </div>

              <!-- PREVIEW MODAL-->
              <template-preview-modal
              :template="previewTemplate"
              :user="user"
            ></template-preview-modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "debounce";
import MarketingNav from "../../partials/MarketingNav";
import TemplatePreviewModal from "./templates-partials/TemplatePreviewModal";

export default {
  props: [],
  data() {
    return {
      loading: true,
      emailSettings: null,
      emailTemplates: [],
      previewTemplate: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    path() {
      return this.$router.currentRoute.fullPath;
    },
    noReplyMessage() {
      return this.emailSettings ? this.emailSettings.noreply_message : null;
    }
  },
  watch: {
    noReplyMessage() {
      this.debounceUpdateEmailSettings();
    }
  },
  methods: {
    debounceUpdateEmailSettings: debounce(function() {
      this.updateEmailSettings();
    }, 300),
    updateEmailSettings() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/marketing/api/" +
            this.user.id +
            "/email-settings",
          this.emailSettings
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
        });
    },
    fetchEmailTemplates() {
      this.loading = true;
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/email-templates/api")
        .then(({ data }) => {
          this.emailTemplates = data;

          this.loading = false;
        });
    },
    showPreviewModal(templateId) {
      this.previewTemplate = this.emailTemplates.filter(function (template) {
        return template.id === templateId;
      })[0];
      $("#previewModal").modal("show");
    },
    async fetchEmailSettings() {
      this.loading = true;
      await this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/marketing/api/" +
            this.user.id +
            "/email-settings"
        )
        .then(({ data }) => {
          this.emailSettings = data;
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchEmailSettings().then(() => {
      if (this.emailSettings) {
        this.fetchEmailTemplates();
      }
    });
  },
  components: {
    MarketingNav,
    TemplatePreviewModal,
  },
};
</script>
